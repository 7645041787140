<template>
  <footer class="w-footer" :style="fStyle">
    <slot></slot>
  </footer>
</template>

<script>
  export default {
    props: {
      padding: {
        type: String,
        default: '10px'
      }
    },
    computed: {
      fStyle: function () {
        return {
          paddingTop: this.padding,
          paddingBottom: this.padding
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w-footer {
    // background-color: #303133;
    color: #fff;

    &-line {
      // width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      span {
        font-size: 14px;
      }

      a {
        color: #909399;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
</style>
